import WaveSurfer from "wavesurfer.js";
import alertify from "alertifyjs";
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
const $ = window.jQuery = require("jquery");
require("gasparesganga-jquery-loading-overlay");
import { browser, isMobile, isSafari } from "./responsive";
import MediaRecorder from "audio-recorder-polyfill";
import mpegEncoder from "audio-recorder-polyfill/mpeg-encoder";

// var MicrophonePlugin;
var wavesurferInput;
var wavesurfer = null;
var time_remaining = 0;
var time_remaining_interval_id = -1;
var saveBlob = null;
var audioCtx;
var audioNode;
var recording = false;

export async function initRecording(roundware) {

  // Record with mp3.
  MediaRecorder.encoder = mpegEncoder;
  MediaRecorder.prototype.mimeType = "audio/mpeg";
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  let recorder;

  // startRecordButton.addEventListener( "click", function(){ recorder.start(); });
  // for future reference, if anything needs to be triggered post-recorder init, do this:
  startRecordButton.addEventListener("click", () => {
    reportRecordingEvent('clickRecord');
    console.log("Start recording!");
    recording = true;

    // Request permissions to record audio
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      recorder = new MediaRecorder(stream);

      // Set record to <audio> when recording will be finished
      recorder.addEventListener('dataavailable', e => {
        console.log('data available: ' + e.data.size);
        saveBlob = e.data;
        preparePlayback();
      })

      recorder.addEventListener('streamerror', e => {
        reportRecordingEvent('errorRecord');
        console.log('Error encountered: ' + e.error.name);
      })

      // Start recording
      recorder.start();
      if (isSafari) {
        visualizeSafari(stream);
      } else {
        visualize();
      }
    })

    console.log(recorder);

    startRecordButton.disabled = true;
    stopRecordButton.disabled = false;
    uploadButton.disabled = true;
    startCountdown(roundware);
    $("#emptywavebox").hide();
    if (isSafari) {
      $("#safariinputmeter").show();
    } else {
      $("#inputmeter").show();
    }
    $("#wavebox").hide();
  });

  stopRecordButton.addEventListener("click", () => {
    reportRecordingEvent('clickStop');
    console.log("stopping recording");
    recording = false;
    recorder.stop();
    if (isSafari) {
      $("#safariinputmeter").hide();
    } else {
      $("#inputmeter").hide();
    }
    $("#wavebox").show();
    console.log('Recorder is stopped');
    resetCountdown(roundware);
    stopRecordButton.disabled = true
    if (isSafari) {
      console.log("you are using safari, do not stop wavesurfer mic plugin.");
    } else {
      console.log("you are NOT using safari. Stop wavesurfer mic plugin!");
      wavesurferInput.microphone.stop();
    }
  });

  uploadButton.addEventListener("click", async function() {
    reportRecordingEvent('clickUpload');
    if (!saveBlob) {
      console.log("nothing to upload");
      return;
    }
    var wavFileName = new Date().toISOString() + ".mp3";
    // uploading overlay
    $("#uploadDiv").LoadingOverlay("show", {
      background: "rgba(0, 0, 0, 0.8)",
      text: "Uploading your recording! Please don't leave this page until complete.",
      textColor: "#cccccc",
      imageColor: "#cccccc",
      maxSize: "40px"
    });
    let assetMetadata = {};
    let speakTagIds = $("#uiSpeakDisplay input:checked").map(function() {
      console.log(`adding tag ${$(this).attr('tag_id')}`);
      return $(this).attr('tag_id');
    }).get().join();
    let referrerTagId = getReferrerTagId();
    if (referrerTagId) {
      if (speakTagIds != "") {
        speakTagIds = `${speakTagIds},${referrerTagId}`;
      } else {
        speakTagIds = `${referrerTagId}`;
      }
    }
    console.log(speakTagIds);
    if (speakTagIds != "") {
      assetMetadata = {
        "tag_ids": speakTagIds,
        "latitude": document.getElementById("speakLatitude").value,
        "longitude": document.getElementById("speakLongitude").value
      };
    } else {
      assetMetadata = {
        "latitude": document.getElementById("speakLatitude").value,
        "longitude": document.getElementById("speakLongitude").value
      };
    }
    console.log(assetMetadata);
    try {
      const data = await roundware.saveAsset(saveBlob, wavFileName, assetMetadata);
      console.log("asset uploaded!");
      console.log(data);
      $("#uploadDiv").LoadingOverlay("hide", true);
      let postRecordingMessage = "Thank you for submitting your recording!<br><br> Would you like to listen to other recordings or view and share your recording page?";
      alertify.confirm('<strong>Success!</strong>', postRecordingMessage,
        function() {
          location.replace("listen.html");
        },
        function() {
          location.replace(`s.html?eid=${data.envelope_ids[0]}`);
        }).set({
          labels: { ok: 'Listen', cancel: 'Share' },
          padding: true,
          transition: 'zoom',
          closable: false,
          defaultFocusOff: true
        });
    } catch (err) {
      console.error(err);
      $("#uploadDiv").LoadingOverlay("hide", true);
      var currentdate = new Date();
      var datetime = currentdate.getDate() + "/"
                     + (currentdate.getMonth()+1)  + "/"
                     + currentdate.getFullYear() + " @ "
                     + currentdate.getHours() + ":"
                     + currentdate.getMinutes() + ":"
                     + currentdate.getSeconds();
      const message = "Unfortunately there has been an error with your recording. Please send the following error message to info@coronadiaries.io if it persists."
      alertify.alert('<strong>Corona Diaries Error</strong>', message +
                     '<br><br><strong>ERROR:</strong><em>' + err +
                     '</em> ' + '(' + datetime + ' session: ' + roundware._sessionId + ')' , function(){
      }).set({
        transition:'zoom',
        closable: false,
        defaultFocusOff: true
      });
    }
  });

  playbackButton.addEventListener("click", function() {
    reportRecordingEvent('clickPlayback');
    if (wavesurfer && saveBlob) {
      wavesurfer.playPause();
    }
  });
  deleteButton.addEventListener("click", function() {
    reportRecordingEvent('clickDelete');
    let deleteAlertMessage = "You are about to delete your current recording in order to make another.<br><br>Are you sure you want to delete it and proceed?";
    alertify.confirm('<strong>Warning!</strong>', deleteAlertMessage,
      function() {
        uploadButton.disabled = true;
        playbackButton.disabled = true;
        deleteButton.disabled = true;
        startRecordButton.disabled = false;
        stopRecordButton.disabled = true;
        $("#emptywavebox").show();
        if (isSafari) {
          $("#safariinputmeter").hide();
        } else {
          $("#inputmeter").hide();
        }
        $("#wavebox").hide();
        if (wavesurfer) {
          wavesurfer.destroy();
        }
        saveBlob = null;
      },
      function() {
        // do nothing!
      }).set({
        labels: { ok: 'Yes', cancel: 'No' },
        padding: true,
        transition: 'zoom',
        closable: false,
        defaultFocusOff: true
      });
  });

}

function preparePlayback() {
  $("wave").remove();
  var url = URL.createObjectURL(saveBlob);

  // set wavesurfer args conditionally for Safari
  const wavesurferArgs = {
    container: ('#waveform'),
    waveColor: 'red',
    progressColor: 'purple',
    height: 150,
    barWidth: 2,
    plugins: [
      TimelinePlugin.create({
        container: '#waveform-timeline',
        primaryColor: '#fff',
        primaryFontColor: 'fff'
      })
    ]
  };
  if (isSafari) {
    wavesurferArgs.backend = 'MediaElement';
  }
  // display waveform with wavesurfer.js
  wavesurfer = WaveSurfer.create(wavesurferArgs);
  wavesurfer.load(url);
  uploadButton.disabled = false;
  playbackButton.disabled = false;
  deleteButton.disabled = false;
}

function reportRecordingEvent(eventTag) {
  try {
    if (typeof gtag === 'function') {
      gtag('event', eventTag);
      console.log('log recording event ' + eventTag);
    } else if (typeof a === 'function') {
      a('event', eventTag);
      console.log('log recording event ' + eventTag);
    } else {
      console.log('no gtag function defined. failed to log recording event ' + eventTag)
    }
  }
  catch (err) {
    console.log("can't log recording event: " + err);
  }
}

function visualize() {
  // input meter visual display
  console.log("visualizing!");
  wavesurferInput = WaveSurfer.create({
    container: '#inputmeter',
    waveColor: 'red',
    height: 150,
    barWidth: 2,
    barHeight: 1.2,
    cursorWidth: 0,
    plugins: [
      MicrophonePlugin.create()
    ]
  });
  wavesurferInput.microphone.on('deviceReady', function(stream) {
    console.log('Wavesurfer input metering ready!', stream);
  });
  wavesurferInput.microphone.on('deviceError', function(code) {
    console.warn('Wavesurfer microphone error: ' + code);
  });
  wavesurferInput.microphone.start();
}

function visualizeSafari(stream) {

  var canvas = document.getElementById("safariinputmeter")

  audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  if (audioCtx.createJavaScriptNode) {
      audioNode = audioCtx.createJavaScriptNode(4096, 1, 1);
  } else if (audioCtx.createScriptProcessor) {
      audioNode = audioCtx.createScriptProcessor(4096, 1, 1);
  } else {
      throw 'WebAudio not supported!';
  }
  audioNode.connect(audioCtx.destination);

  var canvasCtx = canvas.getContext("2d");
  var source = audioCtx.createMediaStreamSource(stream);

  var analyser = audioCtx.createAnalyser();
  analyser.fftSize = 2048;
  var bufferLength = analyser.frequencyBinCount;
  var dataArray = new Uint8Array(bufferLength);

  source.connect(analyser);

  function draw() {
			// get the canvas dimensions
			var width = canvas.width, height = canvas.height;

			// ask the browser to schedule a redraw before the next repaint
			requestAnimationFrame(draw);

			// clear the canvas
			canvasCtx.fillStyle = '#fff';
			canvasCtx.fillRect(0, 0, width, height);

			if (!recording)
				return;

			canvasCtx.lineWidth = 1;
			canvasCtx.strokeStyle = '#f00';

			canvasCtx.beginPath();

			var sliceWidth = width * 1.0 / bufferLength;
			var x = 0;


			analyser.getByteTimeDomainData(dataArray);

			for (var i = 0; i < bufferLength; i++) {

				var v = dataArray[i] / 128.0;
				var y = v * height / 2;

				i == 0 ? canvasCtx.moveTo(x, y) : canvasCtx.lineTo(x, y);
				x += sliceWidth;
			}

			canvasCtx.lineTo(canvas.width, canvas.height/2);
			canvasCtx.stroke();
		}

		draw();

}

/**
 * read max_recording_length from the project config and then use it
 * to display a countdown-timer.
 */
function startCountdown(roundware) {
  time_remaining = roundware._project.maxRecordingLength;
  var minutes = Math.floor(time_remaining / 60);
  var seconds = time_remaining % 60;
  // console.log( hours +':'+ ('0'+minutes).slice(-2) +':'+ ('0'+seconds).slice(-2) );
  $('#countdown').text(minutes + ':' + ('0' + seconds).slice(-2)).css("visibility", "visible");
  time_remaining_interval_id = setInterval(updateCountdown, 1000, time_remaining);
}

/**
 * callback for startCountdown: update the countdown timer until it reaches
 * 0, then clear the interval timer and trigger a click on the record/stop/play
 * button.
 */
function updateCountdown(seconds) {
  time_remaining = --time_remaining;
  var minutes = Math.floor(time_remaining / 60);
  var seconds = time_remaining % 60;
  $('#countdown').text(minutes + ':' + ('0' + seconds).slice(-2));

  if (0 == time_remaining) {
    clearInterval(time_remaining_interval_id);
    $('#stopRecordButton').trigger('click');
  }
}

// reset counter to max recording length

function resetCountdown(roundware) {
  var minutes = Math.floor(roundware._project.maxRecordingLength / 60);
  var seconds = roundware._project.maxRecordingLength % 60;
  $('#countdown').text(minutes + ':' + ('0' + seconds).slice(-2));
  clearInterval(time_remaining_interval_id);
}

// Return tag_id for referrer if passed in url querystring
function getReferrerTagId() {
  var getParams = function (url) {
  	var params = {};
  	var parser = document.createElement('a');
  	parser.href = url;
  	var query = parser.search.substring(1);
  	var vars = query.split('&');
  	for (var i = 0; i < vars.length; i++) {
  		var pair = vars[i].split('=');
  		params[pair[0]] = decodeURIComponent(pair[1]);
  	}
  	return params;
  };

  let urlParams = getParams(window.location.href);
  let ref = urlParams.ref;
  let refTagId;

  switch (ref) {
    case "wgh":
      refTagId = 329;
      break;
    case "mcn":
      refTagId = 330;
      break;
    case "usatoday":
      refTagId = 331;
      break;
    case "world":
      refTagId = 332;
      break;
    case "onpoint":
      refTagId = 333;
      break;
    case "traverse":
      refTagId = 334;
      break;
    case "ga":
      refTagId = 335;
      break;
    default:
      refTagId = null;
  }

  return refTagId;
}
